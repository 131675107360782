<template>
    <div class="addERC20-wrap">
        <CRow>
            <CCard class="cardERC-form">
                <CCardBody v-on:change="isFormChange=true">
                    <ValidationObserver ref="observer" @submit.prevent="userAction">
                        <CForm>
                            <ValidationProvider :name="$t('EDIT_NATIVE_PAGE_FIELD_NAME_VALIDATE_PLATFORM')">
                                <div class="select-wrap form-group">
                                    <label>{{$t("EDIT_NATIVE_PAGE_LABEL_PLATFORM")}}</label>
                                    <span class="disable-txt">{{ platform.platform }}</span>
                                </div>
                            </ValidationProvider>
                            <div class="form-group file-area">
                                <img :src="srcImg || platform.icon" alt="">
                            </div>
                            <ValidationProvider :name="$t('EDIT_NATIVE_PAGE_FIELD_NAME_VALIDATE_NAME')">
                                <div>
                                    <div class="form-group">
                                        <label>{{$t("EDIT_NATIVE_PAGE_LABEL_TOKEN_NAME")}}</label>
                                        <span class="disable-txt">{{ platform.name }}</span>
                                    </div>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('EDIT_NATIVE_PAGE_FIELD_NAME_VALIDATE_SYMBOL')">
                                <div>
                                    <div class="form-group">
                                        <label>{{$t("EDIT_NATIVE_PAGE_LABEL_TOKEN_CODE")}}</label>
                                        <span class="disable-txt">{{ platform.symbol }}</span>
                                    </div>
                                </div>
                            </ValidationProvider>
                            
                            <ValidationProvider :name="$t('EDIT_NATIVE_PAGE_FIELD_NAME_VALIDATE_EST_REWARD')" rules="required|min_value:1|max_value:100" v-slot="{ errors }">
                                <div class="group">
                                    <CInput
                                            :label="$t('EDIT_NATIVE_PAGE_LABEL_REWARD')"
                                            placeholder="0"
                                            type="number"
                                            horizontal
                                            v-model="platform.estimate_earn_per_year"
                                            class="form-group--custom sec-label est-per"
                                            step="0.01"
                                    />
                                    <label class="label-display-only col-sm-3">{{$t("EDIT_NATIVE_PAGE_LABEL_DISPLAY_ONLY")}}</label>
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('EDIT_NATIVE_PAGE_FIELD_NAME_VALIDATE_EST_RANGE')" rules="max:100" v-slot="{ errors }">
                                <div class="group">
                                    <CInput
                                            :label="$t('EDIT_NATIVE_PAGE_LABEL_RANGE')"
                                            horizontal
                                            v-model="platform.estimate_earn_range_per_year"
                                            class="form-group--custom"
                                    />
                                    <label class="label-display-only col-sm-3">{{$t("EDIT_NATIVE_PAGE_LABEL_DISPLAY_ONLY")}}</label>
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <div class="group-lockup-unvote">
                                <ValidationProvider :name="$t('EDIT_NATIVE_PAGE_FIELD_NAME_VALIDATE_LOOKUP_UNVOTE')" rules="required|min_value:0|max_value:1000" v-slot="{ errors }">
                                    <div>
                                        <CInput
                                                :label="$t('EDIT_NATIVE_PAGE_LABEL_LOOKUP_PERIOD')"
                                                placeholder="lockup_unvote"
                                                horizontal
                                                type="number"
                                                v-model="platform.lockup_unvote"
                                                class="form-group--custom sec-label form-lockup"
                                        />
                                        <label class="label-display-only col-sm-3">{{$t("EDIT_NATIVE_PAGE_LABEL_DISPLAY_ONLY")}}</label>
                                        <CRow>
                                            <CCol :sm="{offset:'3'}"></CCol>
                                            <CCol :sm="9">
                                                <p class="error-msg">{{errors[0]}}</p>
                                            </CCol>
                                        </CRow>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider>
                                    <select v-model="platform.lockup_unvote_unit" class="form-control select-lockup">
                                        <option
                                            v-for="reTime in timeUnits"
                                            :value="reTime"
                                            :key="reTime"
                                        >{{$t(`EDIT_NATIVE_PAGE_OPTION_SELECT_${reTime}`)}}</option>
                                    </select>
                                </ValidationProvider>
                            </div>

                            <ValidationProvider :name="$t('EDIT_NATIVE_PAGE_FIELD_NAME_VALIDATE_STATUS')" rules="required" v-slot="{ errors }">
                                <div class="select-wrap form-group">
                                    <label>{{$t("EDIT_NATIVE_PAGE_LABEL_STATUS")}}</label>
                                    <select class="form-control role-select" v-model="platform.status">
                                        <option
                                                v-for="item in status"
                                                v-bind:value="item"
                                                :key="item">{{$t(`EDIT_NATIVE_PAGE_OPTION_SELECT_${renderStatus(item)}`)}}</option>
                                    </select>
                                </div>
                                <CRow>
                                    <CCol :sm="{offset:'3'}"></CCol>
                                    <CCol :sm="9">
                                        <p class="error-msg">{{errors[0]}}</p>
                                    </CCol>
                                </CRow>
                            </ValidationProvider>
                            <div class="btn-ERC20">
                                <CButton class="erc-cancel" @click="openWarningLeaveModal">{{$t("EDIT_NATIVE_PAGE_BUTTON_CANCEL")}}</CButton>
                                <CButton type="submit">{{$t("EDIT_NATIVE_PAGE_BUTTON_SAVE")}}</CButton>
                            </div>
                        </CForm>
                    </ValidationObserver>
                </CCardBody>
            </CCard>
        </CRow>  
        <CModal id="leavePageModal" :show.sync="warningLeavePage" :centered="true">
            <div class="">
                <div class="tit-leave">
                <h4>{{$t("EDIT_NATIVE_PAGE_TITLE_UNSAVED_CHANGES")}}</h4>
                </div>
                <p class="text-modal">{{$t("EDIT_NATIVE_PAGE_CONTENT_UNSAVED_CHANGES")}}</p>
                <p class="text-modal">{{$t("EDIT_NATIVE_PAGE_QUESTION_UNSAVED_CHANGES")}}</p>
            </div>

            <template #footer>
                <CButton
                class="stay-btn"
                @click="warningLeavePage = false"
                color="dark"
                variant="outline"
                >{{$t("EDIT_NATIVE_PAGE_BUTTON_STAY_ON_THIS_PAGE")}}</CButton>
                <router-link class="leave-btn" to="/admin/staking-platform">{{$t("EDIT_NATIVE_PAGE_BUTTON_LEAVE_THIS_PAGE")}}</router-link>
            </template>
        </CModal>
    </div>
</template>
<script>
    import Draggable from "vuedraggable";
    import endpoints from "@/constants/endpoints";
    import responseHelper from '@/utils/response-helper';
    import { mapState, mapActions } from "vuex";
    import platform from "../../../../store/modules/platform";
    export default {
        components: {
            Draggable
        },
        data() {
            return {
                platforms: [],
                options: [],
                plans: [],
                icon: '',
                srcImg: '',
                isFormChange: false,
                warningLeavePage: false,
                status:[
                    -2,
                    -1,
                    0,
                    1,
                ],
                units:[],
            };
        },
        computed: {
            ...mapState({
                platform: state => state.platform.selectedPlatform,
                timeUnits: state => state.platform.timeUnits,
            }),
        },
        async mounted() {
            await this.fetchTimeUnit();
            await this.getPlatforms();
            await this.fetchPayoutList();
            await this.fetchStakingPlan();
        },
        methods: {
            ...mapActions([
                "updateNative",
                "getSelectedPayout",
                "getSelectedPlatform",
                "getSelectedPlan",
                "fetchTimeUnit"
            ]),
            async fetchPayoutList(){
                try {
                    let result = await this.$http.get(
                        endpoints.getStakingPayout(this.platform.id)
                    );
                    if (result.data) {
                        this.platforms=result.data
                        this.total=result.data.length
                        return result.data
                    }
                } catch (err) {
                    this.$notify({
                        group: "notifications",
                        type: "error",
                        title: this.$t('EDIT_NATIVE_PAGE_TITLE_NOTIFY_ERROR'),
                        text: responseHelper.getErrorMessage(err)
                    });
                }
            },
            async fetchStakingPlan(){
                try {
                    let result = await this.$http.get(
                        endpoints.getStakingPlan(this.platform.id)
                    );
                    if (result.data) {
                        this.plans=result.data.items;
                        this.total=result.data.length;
                        return result.data
                    }
                } catch (err) {
                    this.$notify({
                        group: "notifications",
                        type: "error",
                        title: this.$t('EDIT_NATIVE_PAGE_TITLE_NOTIFY_ERROR'),
                        text: responseHelper.getErrorMessage(err)
                    });
                }
            },
            async getPlatforms() {
                let result = await this.$http.get(
                    endpoints.getStakingPlatformConfig,
                );
                if (result.data) {
                    this.options = result.data
                    console.log(result.data)
                }
            },
            uploadImage(event) {
                const image = event.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e => {
                    this.icon = image;
                    this.srcImg = e.target.result;
                };
            },
            async userAction() {
                const isValid = await this.$refs.observer.validate();
                if (!isValid) return;
                const payload = {
                    ...this.platform,
                    icon: this.icon,
                }
                const response = await this.updateNative(payload);
                if (response) {
                    this.$router.back()
                }
            },
            renderStatus(item) {
                if (item === -2) {
                    return 'HIDDEN'
                }
                if (item === -1) {
                    return 'DISABLED'
                }
                if (item === 0) {
                    return 'COMING_SOON'
                }
                if (item === 1) {
                    return 'ENABLED'
                }
            },
            editpayout(platform,item) {
                this.getSelectedPayout(item);
                this.$router.push(`/admin/staking-platform/erc20/edit-payout-max/${platform.id}/payouts/${item.id}`);

            },
            editstakingplan(platform,item) {
                this.getSelectedPlan(item);
                this.$router.push(`/admin/staking-platform/erc20/edit-staking-plan/${platform.id}/plans/${item.id}`);

            },
            goAddnew(){

                this.$router.push(`/admin/staking-platform/erc20/${this.platform.id}/add-plan`);
            },
            openWarningLeaveModal(){
                if(this.isFormChange){
                    this.warningLeavePage=true;
                }else{
                    this.$router.push('/admin/staking-platform');
                }
                console.log(this.isFormChange);
            },
        },
        name: "EditERC2O"
    };
</script>

<style lang="scss">
    .addERC20-wrap{
        .group{
            position: relative;
        }
        .group p{
            left: 25px;
        }
        .row{
            margin: 0;
            padding: 0 24px;
            .cardERC-form{
                width: 55%;
                @media only screen and ( max-width: 1024px ){
                    width: 100%;
                }
                .card-body{
                    padding: 42px 15px;
                    .form-group{
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: flex-start;
                        margin-bottom: 5px;
                        margin-top: 1rem;
                        label{
                            width: 25%;
                            text-align: right;
                            padding-right: 15px;
                            line-height: 25px;
                            color: #3E4B65;
                            // font-family: "SF UI Display";
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                            margin-bottom: 0px;
                        }
                        .disable-txt{
                            color: #8A93A2;
                            /* font-family: "SF UI Display"; */
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                            line-height: 23px;
                            padding-left: 10px;
                            margin: 0;
                        }
                        .form-control{
                            width: 55%;
                            @media only screnn and ( max-width: 1024px ){
                                width: 75%;
                            }
                        }
                        &.file-area{
                            width: 80%;
                            padding-left: 25%;
                            align-items: flex-start;
                            img:not([src=""]){
                                width: 60px;
                                align-self: center;
                                margin-right: 10px;
                            }
                            .ip-file-group{
                                position: relative;
                                width: 60px;
                                min-width: 60px;
                                height: 60px;
                                margin-right: 10px;
                                label{
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    padding: 5px 10px;
                                    /* font-family: "SF UI Display"; */
                                    font-size: 14px;
                                    font-weight: 500;
                                    letter-spacing: 0.5px;
                                    line-height: 16px;
                                    text-align: center;
                                    margin: 0;
                                    border-radius: 4px;
                                    background: #D8D8D8;
                                }
                                #images{
                                    position: absolute;
                                    width: 60px;
                                    height: 60px;
                                    opacity: 0;
                                }
                            }
                            .label-file-support{
                                color: #8A93A2;
                                // font-family: "SF UI Display";
                                font-size: 10px;
                                font-weight: 500;
                                line-height: 16px;
                            }
                        }
                    }
                    .form-group--custom{
                        .col-form-label{
                            text-align: right;
                            padding: 0 15px;
                        }
                        .col-sm-9{
                            flex: 0 0 55%;
                            max-width: 55%;
                            input{
                                width: 100%;
                                &[type="number"]{
                                    width: 65px;
                                    &::after{
                                        content: '%';
                                        position: relative;
                                    }
                                }
                            }
                        }
                        &.sec-label{
                            label{
                                top: -10px;
                                display: flex;
                                flex-direction: column;
                            }
                        }
                        &.est-per{
                            .col-sm-9::after{
                                content: '%';
                                position: absolute;
                                font-size: 14px;
                                font-weight: 300;
                                left: 85px;
                                top: 0;
                                line-height: 35px;
                            }
                        }
                        &.form-lockup{
                            .col-sm-9{
                                align-self: flex-start;
                                margin-top: 8px;
                            }
                        }
                    }
                    .group-lockup-unvote{
                        position: relative;
                        .select-lockup{
                            width: 100px;
                            position: absolute;
                            top: 8px;
                            left: calc( 25% + 80px );
                        }
                    }
                    .label-display-only {
                        font-size: 12px;
                        font-weight: 300;
                        color: #8A93A2;
                        width: 100%;
                        letter-spacing: 0.5px;
                        bottom: 20px;
                        text-align: right;
                    }
                    .row{
                        padding: 0;
                        .col-sm-9{
                            padding: 0;
                            .error-msg{
                                color: #ff0000;
                                margin: 0;
                            }
                        }
                    }
                    .btn-ERC20{
                        width: 100%;
                        margin: 35px 0 0 0;
                        display: flex;
                        justify-content: flex-end;
                        padding-right: 50px;
                        .erc-cancel{
                            color: #657187;
                            // font-family: "SF UI Display";
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                            text-align: center;
                            background: unset;
                            border: none;
                            outline: none !important;
                            box-shadow: none;
                            margin-right: 50px;
                        }
                        button[type="submit"]{
                            min-width: 146px;
                            height: 36px;
                            border-radius: 4px;
                            background-color: #3B4B7E;
                            color: #FFFFFF;
                            // font-family: "SF UI Display";
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                            border: none;
                            outline: none !important;
                            box-shadow: none;
                            text-align: center;
                        }
                    }
                }
            }
            .col--padding{
                padding: 0;
                .card-body{
                    overflow-x: auto;
                }
            }
        }
        .btn-new {
            padding: 0;
            width: 170px;
            height: 32px;
            border-radius: 32px;
            background: #3B4B7E;
            border-color: unset;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            float: right;
            margin-bottom: 15px;
        }
        .title-items{
            color: #657187;
            // font-family: "SF UI Display";
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
        }
    }

#leavePageModal{
  .modal-dialog{
    min-width: 400px;
    max-width: 450px;
    .modal-header{
      display: none;
    }
    .modal-body{
      padding: 24px 20px 0 20px;
      .tit-leave{
        margin-bottom: 20px;
        h4{
          color: #4A4A4A;
          /* font-family: "SF UI Display"; */
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          margin: 0;
        }
      }
      .text-modal{
        color: #4A4A4A;
        // font-family: "SF UI Display";
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
    .modal-footer{
      border: none;
      justify-content: space-between;
      padding: 25px 20px 12px 20px;
      .stay-btn{
        border: 1px solid #CBCBCB;
        border-radius: 5px;
        height: 37px;
        line-height: 37px;
        color: #4A4A4A;
        // font-family: "SF UI Display";
        font-size: 14px;
        line-height: 16px;
        padding: 0 20px;
        &:hover,
        &:visited{
          outline: none;
          background: #fcfcfc;
          color: #4A4A4A;
        }
      }
      .leave-btn{
        height: 37px;
        line-height: 37px;
        padding: 0px 20px;
        border-radius: 4px;
        background-color: #3B4B7E;
        color: #FFFFFF;
        // font-family: "SF UI Display";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}
</style>
